<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Form Config" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title"
                  v-model="callbackTitle"
                  :error="!!errors.callbackformconfig_callback_title"
                  :error-messages="errors.callbackformconfig_callback_title"
                  @input="delete errors.callbackformconfig_callback_title"
                />
                <va-input
                  label="Name Placeholder"
                  v-model="callbackNamePlaceholder"
                  :error="!!errors.callbackformconfig_callback_name_placeholder"
                  :error-messages="errors.callbackformconfig_callback_name_placeholder"
                  @input="delete errors.callbackformconfig_callback_name_placeholder"
                />
                <va-input
                  label="Phone Placeholder"
                  v-model="callbackPhonePlaceholder"
                  :error="!!errors.callbackformconfig_callback_phone_placeholder"
                  :error-messages="errors.callbackformconfig_callback_phone_placeholder"
                  @input="delete errors.callbackformconfig_callback_phone_placeholder"
                />
                <va-input
                  label="Email Placeholder"
                  v-model="callbackEmailPlaceholder"
                  :error="!!errors.callbackformconfig_callback_email_placeholder"
                  :error-messages="errors.callbackformconfig_callback_email_placeholder"
                  @input="delete errors.callbackformconfig_callback_email_placeholder"
                />
                <va-input
                  label="Comment Placeholder"
                  v-model="callbackCommentPlaceholder"
                  :error="!!errors.callbackformconfig_callback_comment_placeholder"
                  :error-messages="errors.callbackformconfig_callback_comment_placeholder"
                  @input="delete errors.callbackformconfig_callback_comment_placeholder"
                />
                <va-input
                  label="Button Label"
                  v-model="callbackButtonLabel"
                  :error="!!errors.callbackformconfig_callback_button_label"
                  :error-messages="errors.callbackformconfig_callback_button_label"
                  @input="delete errors.callbackformconfig_callback_button_label"
                />
                <va-input
                  label="Success title"
                  v-model="callbackSuccessTitle"
                  :error="!!errors.callbackformconfig_callback_success_title"
                  :error-messages="errors.callbackformconfig_callback_success_title"
                  @input="delete errors.callbackformconfig_callback_success_title"
                />
                <va-input
                  type="textarea"
                  label="Success description"
                  v-model="callbackSuccessDescription"
                  :error="!!errors.callbackformconfig_callback_success_description"
                  :error-messages="errors.callbackformconfig_callback_success_description"
                  @input="delete errors.callbackformconfig_callback_success_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title"
                  v-model="volunteerTitle"
                  :error="!!errors.callbackformconfig_volunteer_title"
                  :error-messages="errors.callbackformconfig_volunteer_title"
                  @input="delete errors.callbackformconfig_volunteer_title"
                />
                <va-input
                  label="Name Placeholder"
                  v-model="volunteerNamePlaceholder"
                  :error="!!errors.callbackformconfig_volunteer_name_placeholder"
                  :error-messages="errors.callbackformconfig_volunteer_name_placeholder"
                  @input="delete errors.callbackformconfig_volunteer_name_placeholder"
                />
                <va-input
                  label="Phone Placeholder"
                  v-model="volunteerPhonePlaceholder"
                  :error="!!errors.callbackformconfig_volunteer_phone_placeholder"
                  :error-messages="errors.callbackformconfig_volunteer_phone_placeholder"
                  @input="delete errors.callbackformconfig_volunteer_phone_placeholder"
                />
                <va-input
                  label="Email Placeholder"
                  v-model="volunteerEmailPlaceholder"
                  :error="!!errors.callbackformconfig_volunteer_email_placeholder"
                  :error-messages="errors.callbackformconfig_volunteer_email_placeholder"
                  @input="delete errors.callbackformconfig_volunteer_email_placeholder"
                />
                <va-input
                  label="Comment Placeholder"
                  v-model="volunteerCommentPlaceholder"
                  :error="!!errors.callbackformconfig_volunteer_comment_placeholder"
                  :error-messages="errors.callbackformconfig_volunteer_comment_placeholder"
                  @input="delete errors.callbackformconfig_volunteer_comment_placeholder"
                />
                <va-input
                  label="Button Label"
                  v-model="volunteerButtonLabel"
                  :error="!!errors.callbackformconfig_volunteer_button_label"
                  :error-messages="errors.callbackformconfig_volunteer_button_label"
                  @input="delete errors.callbackformconfig_volunteer_button_label"
                />
                <va-input
                  label="Success title"
                  v-model="volunteerSuccessTitle"
                  :error="!!errors.callbackformconfig_volunteer_success_title"
                  :error-messages="errors.callbackformconfig_volunteer_success_title"
                  @input="delete errors.callbackformconfig_volunteer_success_title"
                />
                <va-input
                  type="textarea"
                  label="Success description"
                  v-model="volunteerSuccessDescription"
                  :error="!!errors.callbackformconfig_volunteer_success_description"
                  :error-messages="errors.callbackformconfig_volunteer_success_description"
                  @input="delete errors.callbackformconfig_volunteer_success_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      callbackTitle: '',
      callbackNamePlaceholder: '',
      callbackPhonePlaceholder: '',
      callbackEmailPlaceholder: '',
      callbackCommentPlaceholder: '',
      callbackButtonLabel: '',
      callbackSuccessTitle: '',
      callbackSuccessDescription: '',

      volunteerTitle: '',
      volunteerNamePlaceholder: '',
      volunteerPhonePlaceholder: '',
      volunteerEmailPlaceholder: '',
      volunteerCommentPlaceholder: '',
      volunteerButtonLabel: '',
      volunteerSuccessTitle: '',
      volunteerSuccessDescription: '',

      errors: [],

      tabTitles: ['Callback', 'Volunteer'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        callbackformconfig_callback_title: this.callbackTitle,
        callbackformconfig_callback_name_placeholder: this.callbackNamePlaceholder,
        callbackformconfig_callback_phone_placeholder: this.callbackPhonePlaceholder,
        callbackformconfig_callback_email_placeholder: this.callbackEmailPlaceholder,
        callbackformconfig_callback_comment_placeholder: this.callbackCommentPlaceholder,
        callbackformconfig_callback_button_label: this.callbackButtonLabel,
        callbackformconfig_callback_success_title: this.callbackSuccessTitle,
        callbackformconfig_callback_success_description: this.callbackSuccessDescription,

        callbackformconfig_volunteer_title: this.volunteerTitle,
        callbackformconfig_volunteer_name_placeholder: this.volunteerNamePlaceholder,
        callbackformconfig_volunteer_phone_placeholder: this.volunteerPhonePlaceholder,
        callbackformconfig_volunteer_email_placeholder: this.volunteerEmailPlaceholder,
        callbackformconfig_volunteer_comment_placeholder: this.volunteerCommentPlaceholder,
        callbackformconfig_volunteer_button_label: this.volunteerButtonLabel,
        callbackformconfig_volunteer_success_title: this.volunteerSuccessTitle,
        callbackformconfig_volunteer_success_description: this.volunteerSuccessDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.callbackformconfig_callback_title || !!this.errors.callbackformconfig_callback_name_placeholder || !!this.errors.callbackformconfig_callback_phone_placeholder || !!this.errors.callbackformconfig_callback_email_placeholder ||
          !!this.errors.callbackformconfig_callback_comment_placeholder || !!this.errors.callbackformconfig_callback_button_label || !!this.errors.callbackformconfig_callback_success_title || !!this.errors.callbackformconfig_callback_success_description
      } else if (tab === 1) {
        return !!this.errors.callbackformconfig_volunteer_title || !!this.errors.callbackformconfig_volunteer_name_placeholder || !!this.errors.callbackformconfig_volunteer_phone_placeholder || !!this.errors.callbackformconfig_volunteer_email_placeholder ||
          !!this.errors.callbackformconfig_volunteer_comment_placeholder || !!this.errors.callbackformconfig_volunteer_button_label || !!this.errors.callbackformconfig_volunteer_success_title || !!this.errors.callbackformconfig_volunteer_success_description
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/1?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/validate/1?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-page/1?lang=${this.locale}`)
        .then(response => {
          this.callbackTitle = response.data.callbackformconfig_callback_title
          this.callbackNamePlaceholder = response.data.callbackformconfig_callback_name_placeholder
          this.callbackPhonePlaceholder = response.data.callbackformconfig_callback_phone_placeholder
          this.callbackEmailPlaceholder = response.data.callbackformconfig_callback_email_placeholder
          this.callbackCommentPlaceholder = response.data.callbackformconfig_callback_comment_placeholder
          this.callbackButtonLabel = response.data.callbackformconfig_callback_button_label
          this.callbackSuccessTitle = response.data.callbackformconfig_callback_success_title
          this.callbackSuccessDescription = response.data.callbackformconfig_callback_success_description

          this.volunteerTitle = response.data.callbackformconfig_volunteer_title
          this.volunteerNamePlaceholder = response.data.callbackformconfig_volunteer_name_placeholder
          this.volunteerPhonePlaceholder = response.data.callbackformconfig_volunteer_phone_placeholder
          this.volunteerEmailPlaceholder = response.data.callbackformconfig_volunteer_email_placeholder
          this.volunteerCommentPlaceholder = response.data.callbackformconfig_volunteer_comment_placeholder
          this.volunteerButtonLabel = response.data.callbackformconfig_volunteer_button_label
          this.volunteerSuccessTitle = response.data.callbackformconfig_volunteer_success_title
          this.volunteerSuccessDescription = response.data.callbackformconfig_volunteer_success_description
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
